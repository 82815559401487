export interface IUsState {
  mask: string;
  value: string;
}

const usStatesConstant: IUsState[] = [
  {
    mask: "Alabama",
    value: "AL",
  },
  {
    mask: "Alaska",
    value: "AK",
  },
  {
    mask: "Arizona",
    value: "AZ",
  },
  {
    mask: "Arkansas",
    value: "AR",
  },
  {
    mask: "California",
    value: "CA",
  },
  {
    mask: "Colorado",
    value: "CO",
  },
  {
    mask: "Connecticut",
    value: "CT",
  },
  {
    mask: "Delaware",
    value: "DE",
  },
  {
    mask: "District Of Columbia",
    value: "DC",
  },
  {
    mask: "Florida",
    value: "FL",
  },
  {
    mask: "Georgia",
    value: "GA",
  },
  {
    mask: "Hawaii",
    value: "HI",
  },
  {
    mask: "Idaho",
    value: "ID",
  },
  {
    mask: "Illinois",
    value: "IL",
  },
  {
    mask: "Indiana",
    value: "IN",
  },
  {
    mask: "Iowa",
    value: "IA",
  },
  {
    mask: "Kansas",
    value: "KS",
  },
  {
    mask: "Kentucky",
    value: "KY",
  },
  {
    mask: "Louisiana",
    value: "LA",
  },
  {
    mask: "Maine",
    value: "ME",
  },
  {
    mask: "Maryland",
    value: "MD",
  },
  {
    mask: "Massachusetts",
    value: "MA",
  },
  {
    mask: "Michigan",
    value: "MI",
  },
  {
    mask: "Minnesota",
    value: "MN",
  },
  {
    mask: "Mississippi",
    value: "MS",
  },
  {
    mask: "Missouri",
    value: "MO",
  },
  {
    mask: "Montana",
    value: "MT",
  },
  {
    mask: "Nebraska",
    value: "NE",
  },
  {
    mask: "Nevada",
    value: "NV",
  },
  {
    mask: "New Hampshire",
    value: "NH",
  },
  {
    mask: "New Jersey",
    value: "NJ",
  },
  {
    mask: "New Mexico",
    value: "NM",
  },
  {
    mask: "New York",
    value: "NY",
  },
  {
    mask: "North Carolina",
    value: "NC",
  },
  {
    mask: "North Dakota",
    value: "ND",
  },
  {
    mask: "Ohio",
    value: "OH",
  },
  {
    mask: "Oklahoma",
    value: "OK",
  },
  {
    mask: "Oregon",
    value: "OR",
  },
  {
    mask: "Pennsylvania",
    value: "PA",
  },
  {
    mask: "Rhode Island",
    value: "RI",
  },
  {
    mask: "South Carolina",
    value: "SC",
  },
  {
    mask: "South Dakota",
    value: "SD",
  },
  {
    mask: "Tennessee",
    value: "TN",
  },
  {
    mask: "Texas",
    value: "TX",
  },
  {
    mask: "Utah",
    value: "UT",
  },
  {
    mask: "Vermont",
    value: "VT",
  },
  {
    mask: "Virginia",
    value: "VA",
  },
  {
    mask: "Washington",
    value: "WA",
  },
  {
    mask: "West Virginia",
    value: "WV",
  },
  {
    mask: "Wisconsin",
    value: "WI",
  },
  {
    mask: "Wyoming",
    value: "WY",
  },
];

export { usStatesConstant };
