import { createContext, Dispatch, SetStateAction } from "react";
import { IdealJobSizes, SpecialtyTag } from "@yardzen-inc/models";

export interface IOnboardState {
  companyImage: File[];
  businessName: string;
  firstName: string;
  lastName: string;
  yearFounded: string;
  numberOfEmployees: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  website: string;
  proofOfInsurance: File[];
  licenseNumber: string;
  licenseState: string;
  bondNumber: string;
  locations: string[];
  maxJobsUnder50k: number;
  maxJobsOver50k: number;
  specialties: SpecialtyTag[];
  typicalClients: string;
  jobsTypicallyAvoided: string;
  idealJobSize: IdealJobSizes | null;
  openToUnder15k: boolean;
  openToPlantingOnly: boolean;
}

export interface IOnboardAction {
  type: "HANDLE INPUT" | "HANDLE ARRAY" | "HANDLE HYDRATE";
  key: keyof IOnboardState;
  value: unknown;
}

const OnboardStateContext = createContext<
  | {
      state: IOnboardState;
      dispatch: Dispatch<IOnboardAction>;
      errors: Record<string, unknown>;
      setErrors: Dispatch<SetStateAction<Record<string, unknown>>>;
    }
  | undefined
>(undefined);

export { OnboardStateContext };
