import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "theme";
import reportWebVitals from "reportWebVitals";
import App from "components/app/App";
import initializeFirebase from "firebase/init.firebase";
import UserProvider from "providers/UserProvider";
import FirebaseClientUtil from "@yardzen-inc/firebase-client-util";
import { SplitProvider } from "@yardzen-inc/react-split";
import ScrollToTop from "components/common/ScrollToTop";

initializeFirebase();
FirebaseClientUtil.setUserClaimListener({
  autoSetWebRoles: true,
});

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <CssBaseline>
        <SplitProvider>
          <UserProvider>
            <Router>
              <ScrollToTop />
              <App />
            </Router>
          </UserProvider>
        </SplitProvider>
      </CssBaseline>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
