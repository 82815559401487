/* eslint-disable react/prop-types */
import React, { FC, useState, SyntheticEvent, MouseEvent } from "react";
import { useOnboard } from "hooks/useOnboard";
import { Box, Grid, TextField, Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { YZTypography, YZButton } from "@yardzen-inc/react-common";
import formatZipCode from "util/formatZipCode";

const OnboardLocation: FC = () => {
  const onboard = useOnboard();

  const [zipInput, setZipInput] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  return (
    <>
      <Box textAlign="center">
        {/* Add component to YZTypography when bug is fixed */}
        <YZTypography variant="h4" type="serif">
          Tell us where you work
        </YZTypography>
        <Box mt={1}>
          <YZTypography variant="body1">
            We&apos;ll send you jobs in these areas. You can update these
            preferences at anytime.
          </YZTypography>
        </Box>
      </Box>
      <Box mt={6}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={9}>
            <TextField
              fullWidth
              variant="outlined"
              id="locations"
              label="Add a zip code"
              name="locations"
              value={zipInput}
              onChange={e => {
                setZipInput(formatZipCode(e.target.value));
                onboard.errors.locations = "";
              }}
              onBlur={e => {
                setZipInput(formatZipCode(e.target.value));
                onboard.errors.locations = "";
              }}
              error={!!onboard.errors.locations}
              helperText={onboard.errors.locations as string}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Box mt={0.75}>
              <YZButton fullWidth color="primary" onClick={handleClick}>
                Add Zip
              </YZButton>
            </Box>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
              message={snackbarMessage}
              action={
                <>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleSnackbarClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </>
              }
            />
          </Grid>
        </Grid>
        <Box mt={4}>
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12}>
              <YZTypography align="center">
                Here are your zip codes. To remove a zip code you entered, just
                click on it.
              </YZTypography>
            </Grid>
            {onboard.state.locations.length ? (
              onboard.state.locations.map(zip => (
                <Grid key={zip} item xs={4} md={2}>
                  <YZButton
                    fullWidth
                    onClick={e => {
                      onboard.handleArray("locations", zip);
                    }}
                  >
                    {zip}
                  </YZButton>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Box fontStyle="italic">
                  <YZTypography align="center">
                    Add your first zip code above.
                  </YZTypography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );

  function handleClick(): void {
    if (onboard.state.locations.includes(zipInput)) {
      setSnackbarMessage("This zip code already exists in your list.");
      setSnackbarOpen(true);
    } else if (zipInput.length !== 5) {
      setSnackbarMessage("A zip code must be 5 digits.");
      setSnackbarOpen(true);
    } else {
      onboard.handleArray("locations", zipInput);
      setZipInput("");
    }
  }

  function handleSnackbarClose(
    e: SyntheticEvent | MouseEvent,
    reason?: string
  ): void {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  }
};

export { OnboardLocation };
export default OnboardLocation;
