import React, { FC } from "react";
import { useParams } from "react-router-dom";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import { Box, useMediaQuery } from "@material-ui/core";
import { YZButton } from "@yardzen-inc/react-common";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

interface IOnboardFooterProps {
  handleNext: () => void;
  handlePrevious: () => void;
  buttonText: () => string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginBottom: "2.5px",
    },
  })
);

const OnboardFooter: FC<IOnboardFooterProps> = (props: IOnboardFooterProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const { activeStep } = useParams<{ activeStep: string }>();

  const { handleNext, handlePrevious, buttonText } = props;

  if (activeStep === "error" || activeStep === "submitted") {
    return null;
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection={isMobile ? "column-reverse" : "row"}
      >
        {activeStep !== "profile" && activeStep !== "submitted" && (
          <Box mt={isMobile ? 1 : 0} component="span">
            <YZButton
              fullWidth={isMobile}
              color="secondary"
              variant="contained"
              size="large"
              onClick={handlePrevious}
            >
              <ArrowBackIcon
                fontSize="inherit"
                className={classes.icon}
                style={{ marginRight: "5px" }}
              />
              Back
            </YZButton>
          </Box>
        )}
        <Box ml={!isMobile ? 4 : 0} component="span">
          <YZButton
            fullWidth={isMobile}
            color="primary"
            variant="contained"
            size="large"
            onClick={handleNext}
          >
            {buttonText()}
            <ArrowForwardIcon
              fontSize="inherit"
              className={classes.icon}
              style={{ marginLeft: "5px" }}
            />
          </YZButton>
        </Box>
      </Box>
    </>
  );
};
export { OnboardFooter };
export default OnboardFooter;
