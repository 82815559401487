import React, { FC, useState, useEffect } from "react";
import firebase from "firebase";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "components/account/Login";
import Register from "components/account/Register";
import Onboard from "components/onboard/Onboard";
import OnboardProvider from "providers/OnboardProvider";
import OnboardStatus from "components/onboard/OnboardStatus";
import Dashboard from "components/dashboard/Dashboard";
import ProtectedRoute from "routes/ProtectedRoute";
import useUser from "hooks/useUser";
import getContractorById from "util/getContractorById";
import { useContractorDashboard } from "@yardzen-inc/react-split";

const Routes: FC = () => {
  const user = useUser();
  const contractorDashboard = useContractorDashboard();

  const [isOnboarded, setIsOnboarded] = useState<boolean>(true);

  useEffect(() => {
    async function onboarded(): Promise<void> {
      if (user.state) {
        const contractor = await getContractorById(user.state.uid);
        setIsOnboarded(!!contractor?.applicationSubmittedAt);
      }
    }

    onboarded();
  }, [user]);

  return (
    <Switch>
      <ProtectedRoute
        path="/"
        unauthorized="/login"
        exact
        component={() =>
          isOnboarded ? <OnboardStatus /> : <Redirect to="/welcome/profile" />
        }
      />
      <Route path="/register" exact component={Register} />
      <Route path="/login" exact component={Login} />
      <Route
        path="/logout"
        render={() => {
          firebase.auth().signOut();
          return <Redirect to="/login" />;
        }}
      />

      <ProtectedRoute
        path="/welcome"
        unauthorized="/login"
        exact
        component={() =>
          isOnboarded ? <Redirect to="/" /> : <Redirect to="/welcome/profile" />
        }
      />
      <ProtectedRoute
        path="/welcome/:activeStep"
        unauthorized="/login"
        exact
        component={() =>
          isOnboarded ? (
            <Redirect to="/" />
          ) : (
            <OnboardProvider>
              <Onboard />
            </OnboardProvider>
          )
        }
      />

      <ProtectedRoute
        path="/dashboard"
        unauthorized="/login"
        exact
        component={() =>
          contractorDashboard ? <Dashboard /> : <h1>Work in progress.</h1>
        }
      />

      {/* To be replaced */}
      <Route component={() => <h1>Not Found!</h1>} />
    </Switch>
  );
};

export default Routes;
export { Routes };
