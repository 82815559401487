import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";

const OnboardSubmitted: FC = () => (
  <>
    <Box textAlign="center">
      {/* Add component to YZTypography when bug is fixed */}
      <YZTypography variant="h4" type="serif">
        Oops, something went wrong.
      </YZTypography>
      <Box mt={1}>
        <YZTypography variant="body1">
          Something went wrong with your submission. Please{" "}
          <a href="/welcome/profile">try again</a>, or contact Yardzen support.
        </YZTypography>
      </Box>
    </Box>
  </>
);

export { OnboardSubmitted };
export default OnboardSubmitted;
