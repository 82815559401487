import React, { FC } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Container, Box, Stepper, Step, StepLabel } from "@material-ui/core";

interface IOnboardStepperProps {
  activeStep: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100vw",
      position: "relative",
      left: "50%",
      right: "50%",
      marginLeft: "-50vw",
      marginRight: "-50vw",
      boxShadow: "0px 3px 0px #F2F2F2",
    },
    stepper: {
      backgroundColor: "transparent",
    },
    button: {
      marginRight: theme.spacing(1),
    },
  })
);

const OnboardStepper: FC<IOnboardStepperProps> = (
  props: IOnboardStepperProps
) => {
  const classes = useStyles();
  const steps = getSteps();
  const { activeStep } = props;

  return (
    <Box className={classes.root}>
      <Container maxWidth="sm">
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Container>
    </Box>
  );
};

function getSteps(): string[] {
  return ["Your profile", "Work locations", "Your preferences"];
}

export { OnboardStepper };
export default OnboardStepper;
