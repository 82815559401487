/* eslint-disable react/prop-types */
import React, { FC, useState, useEffect } from "react";
import firebase from "firebase";
import { Link, RouteChildrenProps, Redirect } from "react-router-dom";
import { Box, CircularProgress } from "@material-ui/core";

import { YZTypography } from "@yardzen-inc/react-common";
import UserAuthForm, {
  IUserAuthFormState,
  IUserAuthFormErrors,
} from "components/account/UserAuthForm";
import { useUser } from "hooks/useUser";

export interface ILoginProps {
  routeProps: RouteChildrenProps;
}

const Login: FC<ILoginProps> = ({ routeProps }) => {
  const user = useUser();

  const [state, setState] = useState<IUserAuthFormState>({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState<IUserAuthFormErrors>({
    email: null,
    password: null,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string | null>(null);

  useEffect(() => {
    const fromState: { from?: string } | null =
      (routeProps?.location?.state as { from?: string }) || null;

    if (user.state) {
      if (fromState?.from) {
        setRedirect(fromState.from);
      }
      setRedirect("/");
    }

    return () => {
      setRedirect(null);
    };
  }, [user, routeProps]);

  return (
    <>
      {!!redirect && <Redirect to={{ pathname: redirect }} />}
      {loading ? (
        <Box mt={8} textAlign="center">
          <CircularProgress size={80} />
        </Box>
      ) : (
        <>
          <Box my={6} textAlign="center">
            {/* Add component to YZTypography when bug is fixed */}
            <YZTypography variant="h4" type="serif">
              Sign in
            </YZTypography>
          </Box>
          <UserAuthForm
            state={state}
            errors={errors}
            buttonText="Sign in now"
            handleInput={handleInput}
            handleSubmit={handleSubmit}
          />
          <Box textAlign="center">
            <YZTypography>
              Don&apos;t have an account? <Link to="/register">Register</Link>.
            </YZTypography>
          </Box>
        </>
      )}
    </>
  );

  function handleInput(key: keyof IUserAuthFormState, value: unknown): void {
    setState({ ...state, [key]: value });
    setErrors({
      email: null,
      password: null,
    });
  }

  async function handleSubmit(): Promise<void> {
    if (state.email && state.password) {
      setLoading(true);
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(state.email, state.password);
      } catch (err) {
        handleError((err as { code: string }).code);
      } finally {
        setLoading(false);
      }
    } else {
      setErrors({
        email: !state.email ? "Missing email" : null,
        password: !state.password ? "Missing password" : null,
      });
    }
  }

  function handleError(errorCode: string): void {
    const err: IUserAuthFormErrors = {
      email: null,
      password: null,
    };

    if (errorCode === "auth/invalid-email") {
      err.email = "This is not a valid email address.";
    } else {
      err.email = "Incorrect email or password.";
      err.password = "Incorrect email or password.";
    }

    setErrors(err);
  }
};

export { Login };
export default Login;
