import React, { FC } from "react";
import { matchPath, useLocation } from "react-router-dom";
import DashboardHeader from "components/header/DashboardHeader";
import BasicHeader from "components/header/BasicHeader";

const logo =
  "https://storage.googleapis.com/yardzen-public-assets/public/BW-Logo-No-Icon.png";

const Header: FC = () => {
  const location = useLocation();

  const isDashboard = matchPath(location.pathname, {
    path: "/dashboard/",
  });

  return isDashboard ? (
    <DashboardHeader logo={logo} />
  ) : (
    <BasicHeader logo={logo} />
  );
};

export { Header };
export default Header;
