import React, { FC } from "react";
import { Container, Box, Grid, TextField } from "@material-ui/core";
import { YZButton } from "@yardzen-inc/react-common";

export interface IUserAuthFormState {
  email: string;
  password: string;
}

export interface IUserAuthFormErrors {
  email: string | null;
  password: string | null;
}

interface IUserAuthFormProps {
  state: IUserAuthFormState;
  errors: IUserAuthFormErrors;
  buttonText: string;
  handleInput: (key: keyof IUserAuthFormState, value: unknown) => void;
  handleSubmit: () => void;
}

const UserAuthForm: FC<IUserAuthFormProps> = (props: IUserAuthFormProps) => {
  const { state, errors, buttonText, handleInput, handleSubmit } = props;

  return (
    <Container maxWidth="xs">
      <Box mt={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="email"
              label="Email"
              name="email"
              value={state.email}
              onChange={e => {
                handleInput("email", e.target.value);
              }}
              onBlur={e => {
                handleInput("email", e.target.value);
              }}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              type="password"
              variant="outlined"
              id="password"
              label="Password"
              name="password"
              value={state.password}
              onChange={e => {
                handleInput("password", e.target.value);
              }}
              onBlur={e => {
                handleInput("password", e.target.value);
              }}
              error={!!errors.password}
              helperText={errors.password}
            />
          </Grid>
        </Grid>
        <Box my={4} textAlign="center">
          <YZButton
            color="primary"
            variant="contained"
            size="large"
            onClick={handleSubmit}
          >
            {buttonText}
          </YZButton>
        </Box>
      </Box>
    </Container>
  );
};

export { UserAuthForm };
export default UserAuthForm;
