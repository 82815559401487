/* eslint-disable no-console */
import firebase, { firestore } from "firebase";
import "firebase/storage";
import "firebase/firestore";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
// import LogRocket from "logrocket";

export interface IMediaRecord {
  readonly userId: string;
  readonly path: string; // <userId>.<uuid>.<unix timestamp in milliseconds>
  readonly originalFileName: string;
  readonly fileType: string;
  readonly downloadUrl?: string;
  readonly uploadedAt: string;
  readonly isDesignerVisible?: boolean;
  readonly title?: string;
  readonly isClientVisible?: boolean;
  readonly tag?: string;
  readonly variant?: string;
  readonly projectId?: string;
  readonly description?: string | null;
}

export interface IMediaVisibility {
  isClientVisible?: boolean;
  isDesignerVisible?: boolean;
  isDesignerContributed?: boolean;
}

export interface IMediaProperties {
  id?: string;
  title?: string;
  file?: File;
  fileType?: string;
  originalFileName?: string;
  userId?: string;
  visibility?: IMediaVisibility;
  downloadURL?: string;
  path?: string;
  uploadedAt?: string;
  tag?: string;
  variant?: string;
  projectId?: string;
  description?: string | null;
}

async function uploadMedia(
  file: File,
  userId: string,
  tag = "",
  onProgressCallback: // eslint-disable-next-line no-shadow
  | ((file: File, snapshot: firebase.storage.UploadTaskSnapshot) => void)
    | null = null,
  variant = "",
  projectId = "",
  fileType: string | null = null
): Promise<firestore.DocumentReference<firestore.DocumentData> | null> {
  if (!userId || !file) {
    return null;
  }

  const fileExtension = ((fileName: string) => {
    if (!fileName.includes(".")) {
      return "";
    }

    return fileName.substr(fileName.lastIndexOf("."), fileName.length);
  })(file.name);

  const storagePath = `media/${userId}.${uuidv4()}.${moment()
    .utc()
    .valueOf()}${fileExtension}`;

  const uploadTask = firebase
    .storage()
    .ref(storagePath)
    .put(file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {
        if (onProgressCallback) {
          onProgressCallback(file, snapshot);
        }
      },
      error => {
        console.error(`Error uploading media: ${error}`);
        // LogRocket.captureException(error);
        reject(error);
      },
      () => {
        resolve(file as never);
        return storeMetadata(
          uploadTask.snapshot,
          file,
          userId,
          tag,
          variant,
          projectId,
          fileType
        );
      }
    );
  });
}

async function storeMetadata(
  snapshot: firebase.storage.UploadTaskSnapshot,
  file: File,
  userId: string,
  tag = "",
  variant = "",
  projectId = "",
  fileType: string | null,
  visibility: IMediaVisibility = {
    isDesignerVisible: true,
    isClientVisible: true,
  }
): Promise<firestore.DocumentReference<firestore.DocumentData>> {
  const mediaRecord: IMediaRecord = {
    userId,
    path: snapshot.ref.fullPath,
    originalFileName: file.name,
    fileType: fileType || file.type,
    uploadedAt: moment().toISOString(),
    isDesignerVisible: !!visibility.isDesignerVisible,
    isClientVisible: !!visibility.isClientVisible,
    tag,
    variant,
    projectId,
  };

  try {
    return firebase
      .firestore()
      .collection("media")
      .add(mediaRecord);
  } catch (error) {
    console.error(
      `Error creating media record ${mediaRecord.originalFileName}`
    );
    throw error;
  }
}

export default uploadMedia;
export { uploadMedia };
