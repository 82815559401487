import React, { FC } from "react";
import { Container } from "@material-ui/core";
import Header from "components/header/Header";
import { Routes } from "routes/Routes";

const App: FC = () => (
  <>
    <Header />
    <Container maxWidth="md">
      <Routes />
    </Container>
  </>
);

export { App };
export default App;
