import { apps, initializeApp } from "firebase";
import firebaseConfig from "firebase/config.firebase";

function initializeFirebase(): void {
  if (!apps.length) {
    initializeApp(firebaseConfig);
  }
}

export { initializeFirebase };
export default initializeFirebase;
