import React, { FC, useState, useEffect } from "react";
import { Box, Paper, CircularProgress } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import theme from "theme";
import useUser from "hooks/useUser";
import getContractorById from "util/getContractorById";

const OnboardStatus: FC = () => {
  const user = useUser();

  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function approved(): Promise<void> {
      if (user.state) {
        const contractor = await getContractorById(user.state.uid);
        if (contractor) {
          setIsApproved(!!contractor.approved);
          setLoading(false);
        }
      }
    }

    approved();
  }, [user]);

  return (
    <>
      {loading ? (
        <Box mt={10} textAlign="center">
          <CircularProgress size={80} />
        </Box>
      ) : (
        <Box textAlign="center" mt={10} mb={6}>
          <Paper>
            <Box p={6}>
              <YZTypography variant="h4" type="serif">
                Your profile has been submitted!
              </YZTypography>
              <Box mt={4}>
                <YZTypography variant="h5">
                  Status:{" "}
                  {isApproved ? (
                    <Box
                      component="span"
                      style={{ color: theme.palette.primary.dark }}
                    >
                      Approved
                    </Box>
                  ) : (
                    <Box
                      component="span"
                      style={{ color: theme.palette.primary.dark }}
                    >
                      Pending approval
                    </Box>
                  )}
                </YZTypography>
              </Box>
              <Box mt={4}>
                {isApproved ? (
                  <YZTypography variant="body1">
                    Congratulations, you&apos;ve been accepted into the Yardzen
                    pros network! You will now begin to receive new job leads
                    from us.
                  </YZTypography>
                ) : (
                  <YZTypography variant="body1">
                    A member of our team will now look over your information,
                    and we will reach out if we have any questions. Once
                    approved, we will be able to start sending you new leads!
                  </YZTypography>
                )}
                <Box mt={4}>
                  <YZTypography variant="body1">
                    If you have any questions, please feel free to{" "}
                    <a href="https://yardzen.com/contactus">contact us</a>.
                  </YZTypography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  );
};

export { OnboardStatus };
export default OnboardStatus;
