import { useContext, Dispatch } from "react";
import {
  OnboardStateContext,
  IOnboardState,
  IOnboardAction,
} from "contexts/onboard.context";
import { Contractor, ContractorProperties } from "@yardzen-inc/models";
import validator from "validator";

interface IUseOnboard {
  state: IOnboardState;
  dispatch: Dispatch<IOnboardAction>;
  errors: Record<string, unknown>;
  handleInput: (key: keyof IOnboardState, value: unknown) => void;
  handleArray: (key: keyof IOnboardState, value: unknown) => void;
  handleHydrate: (contractor: Contractor) => void;
  handleErrors: (step: string) => Record<string, unknown>;
}

function useOnboard(): IUseOnboard {
  const context = useContext(OnboardStateContext);
  if (context === undefined) {
    throw new Error("useOnboard must be used within an OnboardProvider");
  }
  const { state, dispatch, errors, setErrors } = context;

  function handleInput(key: keyof IOnboardState, value: unknown): void {
    dispatch({
      type: "HANDLE INPUT",
      key,
      value,
    });

    setErrors({ ...errors, [key]: "" });
  }

  function handleArray(key: keyof IOnboardState, value: unknown): void {
    dispatch({
      type: "HANDLE ARRAY",
      key,
      value,
    });

    setErrors({ ...errors, [key]: "" });
  }

  function handleHydrate(contractor: Contractor): void {
    let newOnboardProps: Partial<IOnboardState> = {
      ...state,
    };

    Object.keys((contractor as unknown) as IOnboardState).forEach(key => {
      const onboardStateProperties = Object.keys(state);

      if (onboardStateProperties.includes(key)) {
        newOnboardProps = {
          ...newOnboardProps,
          [key]: contractor[key as keyof ContractorProperties] || "",
        };
      }
    });

    dispatch({
      type: "HANDLE HYDRATE",
      key: "" as keyof IOnboardState,
      value: newOnboardProps,
    });
  }

  function handleErrors(step: string): Record<string, unknown> {
    const err: Record<string, unknown> = {};

    Object.keys(state).forEach(key => {
      const value = state[key as keyof IOnboardState];

      function handleRequiredFields(fields: string[]): void {
        fields.forEach(field => {
          if (key === field) {
            if (Array.isArray(value)) {
              err[field] = !value.length ? "This field is required." : "";
            } else {
              err[field] = !value ? "This field is required." : "";
            }
          }
        });
      }

      if (step === "profile") {
        const requiredFields = [
          "companyImage",
          "businessName",
          "firstName",
          "lastName",
          "yearFounded",
          "numberOfEmployees",
          "street",
          "city",
          "state",
          "zip",
          "phone",
          "proofOfInsurance",
        ];

        handleRequiredFields(requiredFields);

        if (key === "zip") {
          if ((value as string).length < 5) {
            err.zip = "Zip code must be 5 digits.";
          }
        }

        if (key === "phone") {
          if (value && !validator.isMobilePhone(value as string, "en-US")) {
            err.phone = "Must be a valid, 10 digit phone number.";
          }
        }

        if (key === "website") {
          if (value && !validator.isURL(value as string)) {
            err.website = "Must be a valid URL.";
          } else {
            err.website = "";
          }
        }
      }

      if (step === "location") {
        if (key === "locations") {
          if (!(value as []).length) {
            err.locations = "Must add at least one zip code.";
          }
        }
      }

      if (step === "preferences") {
        const requiredFields = [
          "specialties",
          "jobsTypicallyAvoided",
          "typicalClients",
          "idealJobSize",
        ];

        handleRequiredFields(requiredFields);
      }
    });

    setErrors({ ...err });
    return { ...err };
  }

  return {
    state,
    dispatch,
    errors,
    handleInput,
    handleArray,
    handleHydrate,
    handleErrors,
  };
}

export default useOnboard;
export { useOnboard };
