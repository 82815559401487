/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType, useContext, createElement } from "react";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";
import { UserContext } from "contexts/user.context";

export interface IProtectedRouteProps extends RouteProps {
  unauthorized?:
    | string
    | ComponentType<RouteComponentProps<any>>
    | ComponentType<any>;
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
}

const ProtectedRoute = ({
  component,
  unauthorized,
  ...rest
}: IProtectedRouteProps): JSX.Element | null => {
  const user = useContext(UserContext);

  if (user === null) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={props => {
        const newProps = {
          ...props,
          user,
        };

        return getRedirect(newProps);
      }}
    />
  );

  function getRedirect(newProps: RouteComponentProps): JSX.Element {
    if (!!user && !user.isAnonymous) {
      return createElement(component, newProps);
    }

    if (!unauthorized) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: {
              from: rest.location?.pathname,
            },
          }}
        />
      );
    }

    if (!!unauthorized && typeof unauthorized === "string") {
      return (
        <Redirect
          to={{
            pathname: unauthorized,
            state: {
              from: rest.location?.pathname,
            },
          }}
        />
      );
    }
    return createElement(unauthorized);
  }
};

export { ProtectedRoute };
export default ProtectedRoute;
