import validator from "validator";

const specialChar = /(?=.*[!@#$%^&])/;
const numbers = /(?=.*[0-9])/;
const eightOrMoreChars = /(?=.{8,})/;
const lowercase = /(?=.*[a-z])/;
const uppercase = /(?=.*[A-Z])/;

export function invalidEmail(email: string): string | null {
  if (email === "") {
    return "Missing email address.";
  }
  if (!validator.isEmail(email)) {
    return "Must be a valid email address.";
  }

  return null;
}

export function invalidPassword(pass: string): string | null {
  if (pass === "") {
    return "Missing password.";
  }
  if (!specialChar.test(pass)) {
    return "Password must contain one of the following: !@$#$%^&.";
  }

  if (!numbers.test(pass)) {
    return "Password must contain letters and numbers.";
  }

  if (!eightOrMoreChars.test(pass)) {
    return "Password must be at least eight characters long.";
  }

  if (!lowercase.test(pass)) {
    return "Password must contain atleast one lowercase letter.";
  }
  if (!uppercase.test(pass)) {
    return "Password must contain atleast one uppercase letter.";
  }

  return null;
}
