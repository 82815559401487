/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { FC } from "react";
import { EditControl } from "react-leaflet-draw";
import { DrawEvents } from "leaflet";

import { IGeoShape } from "types/leaflet/IGeoShape";
import { mapLayerToGeoShape } from "convertors/leaflet/mapLayerToGeoShape";

interface ILeafletDrawProps {
  onCreated: (shape: IGeoShape) => void;
  onEdited: (shapes: IGeoShape[]) => void;
  onDeleted: (shapes: IGeoShape[]) => void;
}

const LeafletDraw: FC<ILeafletDrawProps> = ({
  onCreated,
  onEdited,
  onDeleted,
}) => (
  <EditControl
    position="topright"
    onCreated={(event: DrawEvents.Created) => {
      onCreated(mapLayerToGeoShape(event.layer));
    }}
    onEdited={(event: DrawEvents.Edited) => {
      onEdited(
        event.layers.getLayers().map(layer => mapLayerToGeoShape(layer))
      );
    }}
    onDeleted={(event: DrawEvents.Deleted) => {
      onDeleted(
        event.layers.getLayers().map(layer => mapLayerToGeoShape(layer))
      );
    }}
    draw={{
      rectangle: false,
      polyline: false,
      marker: false,
      // circle: false,
      circlemarker: false,
    }}
  />
);

export default LeafletDraw;
