import React, { FC, useState, MouseEvent, KeyboardEvent } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import {
  useMediaQuery,
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { NavLink, useHistory } from "react-router-dom";
import { YZTypography } from "@yardzen-inc/react-common";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import DashboardHeaderDrawer from "components/header/DashboardHeaderDrawer";

interface IDashboardHeaderProps {
  logo: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navLink: {
      color: "#4c4c4c",
      textDecoration: "none",
      height: "100%",
      display: "block",
      padding: "24px 20px 0",
      "&:hover": {
        color: "#5d5d5d",
      },
    },
    activeNavLink: {
      fontWeight: "bold",
      color: "inherit",
      borderBottom: `3px solid ${theme.palette.primary.main}`,
    },
    userSubtitle: {
      color: "#4c4c4c",
      textTransform: "uppercase",
      fontSize: "8px",
      textAlign: "right",
    },
    avatar: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      backgroundColor: theme.palette.primary.main,
    },
  })
);

const Header: FC<IDashboardHeaderProps> = (props: IDashboardHeaderProps) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { logo } = props;

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
    setMenuAnchorEl(e.currentTarget);
  };

  const handleClose = (): void => {
    setMenuAnchorEl(null);
  };

  const toggleDrawer = (open: boolean) => (e: KeyboardEvent | MouseEvent) => {
    if (
      e.type === "keydown" &&
      ((e as KeyboardEvent).key === "Tab" ||
        (e as KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setShowDrawer(open);
  };

  return (
    <Box bgcolor="white" px={3}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Box>
            <a href="/">
              <img
                src={logo}
                style={{ width: "130px", display: "block" }}
                alt="Yardzen logo"
              />
            </a>
          </Box>
          {!isMobile && (
            <Box display="flex" ml={8} style={{ height: "100%" }}>
              <Box>
                <YZTypography style={{ height: "100%" }}>
                  <NavLink
                    to="/dashboard/leads"
                    className={classes.navLink}
                    activeClassName={classes.activeNavLink}
                  >
                    Leads
                  </NavLink>
                </YZTypography>
              </Box>
              <Box>
                <YZTypography style={{ height: "100%" }}>
                  <NavLink
                    to="/dashboard/projects"
                    className={classes.navLink}
                    activeClassName={classes.activeNavLink}
                  >
                    Current Projects
                  </NavLink>
                </YZTypography>
              </Box>
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center" py={1}>
          {isMobile ? (
            <>
              <IconButton onClick={toggleDrawer(true)}>
                <MenuIcon style={{ fontSize: 40 }} />
              </IconButton>
              <DashboardHeaderDrawer
                open={showDrawer}
                toggleDrawer={toggleDrawer}
              />
            </>
          ) : (
            <>
              <Box>
                <YZTypography>Cool Contractors</YZTypography>
                <YZTypography variant="body2" className={classes.userSubtitle}>
                  Certified Developer
                </YZTypography>
              </Box>
              <Box ml={2}>
                <Button
                  aria-controls="dashboard-header-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <Avatar
                    src="/path/to/file"
                    alt="Contractor Name"
                    className={classes.avatar}
                  />
                  <ExpandMoreIcon />
                </Button>
                <Menu
                  id="dashboard-header-menu"
                  anchorEl={menuAnchorEl}
                  keepMounted
                  open={Boolean(menuAnchorEl)}
                  onClose={handleClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                  PaperProps={{
                    style: {
                      marginTop: "8px",
                      minWidth: "200px",
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      history.push("/dashboard/profile");
                      handleClose();
                    }}
                  >
                    Edit profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push("/dashboard/help");
                      handleClose();
                    }}
                  >
                    Help
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push("/logout");
                      handleClose();
                    }}
                  >
                    Sign out
                  </MenuItem>
                </Menu>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export { Header };
export default Header;
