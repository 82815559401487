import React, { FC, KeyboardEvent, MouseEvent } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemText,
} from "@material-ui/core";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

interface IDashboardHeaderDrawerProps {
  open: boolean;
  toggleDrawer: (open: boolean) => (e: KeyboardEvent | MouseEvent) => void;
}

const DashboardHeaderDrawer: FC<IDashboardHeaderDrawerProps> = (
  props: IDashboardHeaderDrawerProps
) => {
  const classes = useStyles();
  const history = useHistory();

  const { open, toggleDrawer } = props;

  return (
    <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          <ListItem button>
            <ListItemText
              primary="Leads"
              onClick={() => {
                history.push("/dashboard/leads");
              }}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Current Projects"
              onClick={() => {
                history.push("/dashboard/projects");
              }}
            />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button>
            <ListItemText
              primary="Edit profile"
              onClick={() => {
                history.push("/dashboard/profile");
              }}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Help"
              onClick={() => {
                history.push("/dashboard/help");
              }}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Sign out"
              onClick={() => {
                history.push("/logout");
              }}
            />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export { DashboardHeaderDrawer };
export default DashboardHeaderDrawer;
