/* eslint-disable react/require-default-props */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
import React, { FC, useState, useCallback, ReactElement } from "react";
import clsx from "clsx";
import { IOnboardState } from "contexts/onboard.context";
import { useOnboard } from "hooks/useOnboard";
import { useUser } from "hooks/useUser";
import { useDropzone } from "react-dropzone";
import uploadMedia from "util/uploadMedia";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import CheckIcon from "@material-ui/icons/Check";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Media } from "@yardzen-inc/models";
import { firestore } from "firebase";
import getMediaWithDownloadURL from "util/getMediaWithDownloadURL";
import { useAuthChange } from "@yardzen-inc/firebase-client-util";

interface IOnboardDropzoneProps {
  id: string;
  stateKey: keyof IOnboardState;
  inputLabel: string;
  acceptedFileTypes: string;
  maxFiles: number;
  showError?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropzone: {
      height: "300px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    dropzoneBase: {
      border: `3px dashed ${theme.palette.primary.main}`,
    },
    dropzoneDrag: {
      border: `3px solid ${theme.palette.primary.main}`,
      color: "#a2a2a2",
    },
    textContainer: {
      position: "relative",
      top: "50%",
      transform: "translateY(-50%)",
      maxWidth: "8rem",
      margin: "auto",
    },
    imageContainer: {
      padding: "1rem",
      height: "100%",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    label: {
      marginBottom: "1rem",
      textAlign: "center",
    },
    errorState: {
      borderColor: theme.palette.error.main,
    },
    errorLabel: {
      color: theme.palette.error.main,
    },
  })
);

const OnboardDropzone: FC<IOnboardDropzoneProps> = (
  props: IOnboardDropzoneProps
) => {
  const onboard = useOnboard();
  const user = useUser();

  const [media, setMedia] = useState<Media[]>([]);
  const [loadingMedia, setLoadingMedia] = useState(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [dropAccepted, setDropAccepted] = useState<boolean>(true);

  const {
    stateKey,
    inputLabel,
    acceptedFileTypes,
    maxFiles,
    id,
    showError,
  } = props;

  useAuthChange(() => {
    setLoadingMedia(true);
    if (user.state) {
      return firestore()
        .collection("media")
        .where("userId", "==", user.state.uid)
        .where("tag", "==", stateKey)
        .onSnapshot(async snap => {
          const fetchedMedia = await getMediaWithDownloadURL(snap);
          setMedia(fetchedMedia);
          setLoadingMedia(false);
        });
    }
  }, [user.state, stateKey]);

  const dropzoneDisabled = loadingMedia || media.length === maxFiles;

  const error: string | null = showError
    ? (onboard.errors[stateKey] as string)
    : null;

  const onDrop = useCallback(
    async acceptedFiles => {
      if (user.state) {
        try {
          setLoadingMedia(true);
          await uploadMedia(acceptedFiles[0], user.state.uid, stateKey);

          onboard.handleInput(stateKey, [
            {
              userId: user.state.uid,
              tag: stateKey,
              path: acceptedFiles[0].path,
              name: acceptedFiles[0].name,
              type: acceptedFiles[0].type,
            },
          ]);
        } catch (err) {
          console.error(`Error uploading media: ${err}`);
        }
      }
    },
    [onboard, user.state, stateKey]
  );

  const onDropAccepted = useCallback(() => {
    setDropAccepted(true);
    setIsDragging(false);
  }, []);

  const onDropRejected = useCallback(() => {
    setDropAccepted(false);
    setIsDragging(false);
    setLoadingMedia(false);
  }, []);

  const onDragEnter = useCallback(() => {
    setIsDragging(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropAccepted,
    onDropRejected,
    onDragEnter,
    onDragLeave,
    accept: acceptedFileTypes,
    maxFiles,
    disabled: dropzoneDisabled,
  });

  const classes = useStyles();
  const handleDragClass = isDragging ? "dropzoneDrag" : "dropzoneBase";

  let filesList = [];

  if (Array.isArray(media)) {
    filesList = media.map((file: Media) => (
      <ListItem
        button
        key={file.path}
        onClick={async () => {
          onboard.handleInput(stateKey, []);
          await file.delete();
        }}
        alignItems="center"
      >
        <ListItemIcon>
          <CheckIcon color="primary" fontSize="large" />
        </ListItemIcon>
        <ListItemText
          primary={file.originalFileName}
          secondary="Click to remove"
        />
      </ListItem>
    ));
  } else {
    throw new Error(
      // eslint-disable-next-line max-len
      `${stateKey}'s value must be an array to use with OnboardDropzone component.
      Value is currently a ${typeof onboard.state[stateKey]}.`
    );
  }

  return (
    <>
      <Container id={id} maxWidth="xs">
        <InputLabel
          className={clsx(
            classes.label,
            error ? classes.errorLabel : undefined
          )}
        >
          {error || inputLabel}
        </InputLabel>
        <Paper elevation={2}>
          <div
            {...getRootProps()}
            className={clsx(
              classes.dropzone,
              classes[handleDragClass],
              error ? classes.errorState : undefined
            )}
          >
            <input {...getInputProps()} />
            {renderZoneContents()}
          </div>
        </Paper>
        {dropAccepted && <List aria-label="Uploaded files">{filesList}</List>}
        {!dropAccepted && (
          <List aria-label="Upload error">
            <ListItem
              button
              onClick={() => {
                onboard.handleInput(stateKey, []);
                setMedia([]);
              }}
              alignItems="center"
            >
              <ListItemIcon>
                <ErrorOutlineIcon color="error" fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary="File extension not allowed"
                secondary="Please try again with another file"
              />
            </ListItem>
          </List>
        )}
      </Container>
    </>
  );

  function renderZoneContents(): ReactElement | ReactElement[] {
    if (loadingMedia) {
      return (
        <Box
          display="flex"
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          padding={2}
          textAlign="center"
        >
          <CircularProgress />
        </Box>
      );
    }
    if (!media.length) {
      return (
        <Box className={classes.textContainer} textAlign="center">
          <PhotoLibraryIcon fontSize="large" />
          <YZTypography variant="body1" type="serif">
            Drag & drop or click to browse
          </YZTypography>
        </Box>
      );
    }
    return media.map((file: Media) =>
      /^image/.test(file.fileType) ? (
        <Box key={file.originalFileName} className={classes.imageContainer}>
          <img
            src={file.downloadURL}
            alt={file.originalFileName}
            className={classes.image}
          />
        </Box>
      ) : (
        <Box
          key={file.originalFileName}
          className={classes.textContainer}
          textAlign="center"
        >
          <CheckIcon fontSize="large" color="primary" />
          <YZTypography variant="body1" type="serif">
            File uploaded successfully.
          </YZTypography>
        </Box>
      )
    );
  }
};

export { OnboardDropzone };
export default OnboardDropzone;
