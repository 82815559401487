/* eslint-disable react/prop-types */
import React, { FC } from "react";
import { Box } from "@material-ui/core";

interface IBasicHeaderProps {
  logo: string;
}

const BasicHeader: FC<IBasicHeaderProps> = (props: IBasicHeaderProps) => {
  const { logo } = props;

  return (
    <Box bgcolor="white" py={2} textAlign="center">
      <a href="/">
        <img src={logo} style={{ height: "40px" }} alt="Yardzen logo" />
      </a>
    </Box>
  );
};

export { BasicHeader };
export default BasicHeader;
