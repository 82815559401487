/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { FC, useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { YZTypography } from "@yardzen-inc/react-common";
import UserAuthForm, {
  IUserAuthFormState,
  IUserAuthFormErrors,
} from "components/account/UserAuthForm";
import { invalidEmail, invalidPassword } from "util/validations";
import firebase from "firebase";
import { useUser } from "hooks/useUser";
import FirebaseClientUtil from "@yardzen-inc/firebase-client-util";

export interface IRegisterProps {
  onSuccessfulRegister?: () => void;
}

const Register: FC<IRegisterProps> = ({ onSuccessfulRegister }) => {
  const [state, setState] = useState<IUserAuthFormState>({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState<IUserAuthFormErrors>({
    password: null,
    email: null,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [successfulRegister, setSuccessfulRegister] = useState<boolean>(false);

  const history = useHistory();
  const user = useUser();

  useEffect(() => {
    if (!!user && successfulRegister) {
      if (onSuccessfulRegister) {
        onSuccessfulRegister();
      } else {
        history.push("/welcome/profile");
      }
    }
  }, [successfulRegister, onSuccessfulRegister, user, history]);

  return (
    <>
      {loading ? (
        <Box mt={8} textAlign="center">
          <CircularProgress size={80} />
        </Box>
      ) : (
        <>
          <Box my={6} textAlign="center">
            {/* Add component to YZTypography when bug is fixed */}
            <YZTypography variant="h4" type="serif">
              Register
            </YZTypography>
          </Box>
          <UserAuthForm
            state={state}
            errors={errors}
            buttonText="Register now"
            handleSubmit={handleSubmit}
            handleInput={handleInput}
          />
          <Box textAlign="center">
            <YZTypography>
              Already have an account? <Link to="/login">Sign in</Link>.
            </YZTypography>
          </Box>
        </>
      )}
    </>
  );

  function handleInput(key: keyof IUserAuthFormState, value: unknown): void {
    setState({ ...state, [key]: value });
  }

  async function handleSubmit(): Promise<void> {
    if (!invalidEmail(state.email) && !invalidPassword(state.password)) {
      setLoading(true);
      try {
        const register = await firebase
          .auth()
          .createUserWithEmailAndPassword(state.email, state.password);

        if (register.user) {
          await createProfile(register.user.uid);
          await assignRoles();
        }

        setSuccessfulRegister(true);
      } catch (err) {
        handleError((err as { code: string }).code);
      } finally {
        setLoading(false);
      }
    } else {
      setErrors({
        email: invalidEmail(state.email),
        password: invalidPassword(state.password),
      });
    }
  }

  function handleError(errorCode: string): void {
    const err: IUserAuthFormErrors = {
      email: null,
      password: null,
    };

    if (errorCode === "auth/email-already-in-use") {
      err.email = "Email is already in use, please select a different one.";
    } else {
      err.email = "Something went wrong, please try again later.";
      err.password = "Something went wrong, please try again later.";
    }

    setErrors(err);
  }

  async function createProfile(userId: string): Promise<void> {
    const path = userId;
    const data = {
      isContractor: true,
      // This is temporary until contractor permissions are updated and the purchased field isn't required
      purchased: true,
    };
    try {
      await firebase
        .firestore()
        .collection("profiles")
        .doc(path)
        .set(data, { merge: true });
    } catch (err) {
      console.error(
        `Error creating pros account: ${err}. data: ${JSON.stringify(data)}`
      );
    }
  }

  async function assignRoles(): Promise<void> {
    await FirebaseClientUtil.setWebRolesForUser();
  }
};

export { Register };
export default Register;
