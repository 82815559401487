import { Circle, GeoJSON, Layer, Polygon } from "leaflet";
import { IGeoJSONObject, IMapLayer } from "types/leaflet/IGeoShape";
import { LeafletShape } from "../../types/leaflet/LeafletShape";

function createLayerFromGeoJsonString(location: string): Layer | null {
  const object = JSON.parse(location) as IMapLayer;
  switch (object.type) {
    case LeafletShape.CIRCLE:
      return createCircleFromGeoShape(object.feature);
    case LeafletShape.POLYGON:
      return createPolygonFromGeoShape(object.feature);
    default:
      return null;
  }
}

function createCircleFromGeoShape(object: IGeoJSONObject): Circle {
  return new Circle(
    GeoJSON.coordsToLatLng(object.geometry.coordinates),
    object.properties.radius
  );
}

function createPolygonFromGeoShape(object: IGeoJSONObject): Polygon {
  return new Polygon(GeoJSON.coordsToLatLngs(object.geometry.coordinates[0]));
}

export default createLayerFromGeoJsonString;
export { createLayerFromGeoJsonString };
