/* eslint-disable react/prop-types */
import React, { FC } from "react";
import { useOnboard } from "hooks/useOnboard";
import {
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import OnboardDropzone from "components/onboard/OnboardDropzone";
import { usStatesConstant, IUsState } from "util/usStatesConstant";
import numericRange from "util/numericRange";
import formatZipCode from "util/formatZipCode";

const OnboardProfile: FC = () => {
  const onboard = useOnboard();

  const yearFoundedArray = numericRange(
    1950,
    new Date().getFullYear(),
    1
  ).reverse();

  return (
    <>
      <Box textAlign="center">
        {/* Add component to YZTypography when bug is fixed */}
        <YZTypography variant="h4" type="serif">
          Complete your Profile
        </YZTypography>
        <Box mt={1}>
          <YZTypography variant="body1">
            This is the first step before we start introducing you to Yardzen
            clients.
          </YZTypography>
        </Box>
      </Box>
      <Box mt={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <OnboardDropzone
              id="companyImage"
              stateKey="companyImage"
              inputLabel="Upload headshot or company logo"
              acceptedFileTypes="image/*"
              maxFiles={1}
              showError={!!onboard.errors.companyImage}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="businessName"
              label="Business name"
              name="businessName"
              value={onboard.state.businessName}
              onChange={e => {
                onboard.handleInput("businessName", e.target.value);
              }}
              onBlur={e => {
                onboard.handleInput("businessName", e.target.value);
              }}
              error={!!onboard.errors.businessName}
              helperText={onboard.errors.businessName as string}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="firstName"
              label="First name"
              name="firstName"
              value={onboard.state.firstName}
              onChange={e => {
                onboard.handleInput("firstName", e.target.value);
              }}
              onBlur={e => {
                onboard.handleInput("firstName", e.target.value);
              }}
              error={!!onboard.errors.firstName}
              helperText={onboard.errors.firstName as string}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="lastName"
              label="Last name"
              name="lastName"
              value={onboard.state.lastName}
              onChange={e => {
                onboard.handleInput("lastName", e.target.value);
              }}
              onBlur={e => {
                onboard.handleInput("lastName", e.target.value);
              }}
              error={!!onboard.errors.lastName}
              helperText={onboard.errors.lastName as string}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel required id="year-founded-select-input">
                Year founded
              </InputLabel>
              <Select
                value={onboard.state.yearFounded}
                error={!!onboard.errors.yearFounded}
                labelId="year-founded-select-input"
                id="yearFounded"
                label="Year founded"
                onChange={e => {
                  onboard.handleInput("yearFounded", e.target.value);
                }}
                onBlur={e => {
                  onboard.handleInput("yearFounded", e.target.value);
                }}
              >
                {yearFoundedArray.map((year: number) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              {!!onboard.errors.yearFounded && (
                <FormHelperText error>This field is required.</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              type="number"
              inputProps={{ min: 0 }}
              variant="outlined"
              id="numberOfEmployees"
              label="# of employees"
              name="numberOfEmployees"
              value={onboard.state.numberOfEmployees}
              onChange={e => {
                onboard.handleInput(
                  "numberOfEmployees",
                  Number(e.target.value) >= 0 ? e.target.value : ""
                );
              }}
              onBlur={e => {
                onboard.handleInput(
                  "numberOfEmployees",
                  Number(e.target.value) >= 0 ? e.target.value : ""
                );
              }}
              error={!!onboard.errors.numberOfEmployees}
              helperText={onboard.errors.numberOfEmployees as string}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="street"
              label="Street"
              name="street"
              value={onboard.state.street}
              onChange={e => {
                onboard.handleInput("street", e.target.value);
              }}
              onBlur={e => {
                onboard.handleInput("street", e.target.value);
              }}
              error={!!onboard.errors.street}
              helperText={onboard.errors.street as string}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="city"
              label="City"
              name="city"
              value={onboard.state.city}
              onChange={e => {
                onboard.handleInput("city", e.target.value);
              }}
              onBlur={e => {
                onboard.handleInput("city", e.target.value);
              }}
              error={!!onboard.errors.city}
              helperText={onboard.errors.city as string}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel required id="state-select-input">
                State
              </InputLabel>
              <Select
                error={!!onboard.errors.state}
                value={onboard.state.state}
                labelId="state-select-input"
                id="state"
                label="State"
                onChange={e => {
                  onboard.handleInput("state", e.target.value);
                }}
                onBlur={e => {
                  onboard.handleInput("state", e.target.value);
                }}
              >
                {usStatesConstant.map((usState: IUsState) => (
                  <MenuItem key={usState.mask} value={usState.value}>
                    {usState.value}
                  </MenuItem>
                ))}
              </Select>
              {!!onboard.errors.state && (
                <FormHelperText error>This field is required.</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="zip"
              value={onboard.state.zip}
              label="Zip Code"
              name="zip"
              onChange={e => {
                onboard.handleInput("zip", formatZipCode(e.target.value));
              }}
              onBlur={e => {
                onboard.handleInput("zip", formatZipCode(e.target.value));
              }}
              error={!!onboard.errors.zip}
              helperText={onboard.errors.zip as string}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="phone"
              label="Phone number"
              value={handlePhone(onboard.state.phone).formatted}
              name="phone"
              onChange={e => {
                onboard.handleInput(
                  "phone",
                  handlePhone(e.target.value).unformatted
                );
              }}
              onBlur={e => {
                onboard.handleInput(
                  "phone",
                  handlePhone(e.target.value).unformatted
                );
              }}
              error={!!onboard.errors.phone}
              helperText={onboard.errors.phone as string}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              id="website"
              label="Contractor website"
              name="website"
              value={onboard.state.website}
              onChange={e => {
                onboard.handleInput("website", e.target.value);
              }}
              onBlur={e => {
                onboard.handleInput("website", e.target.value);
              }}
              error={!!onboard.errors.website}
              helperText={onboard.errors.website as string}
            />
          </Grid>
          <Grid item xs={12}>
            <OnboardDropzone
              id="proofOfInsurance"
              stateKey="proofOfInsurance"
              inputLabel="Upload proof of insurance"
              acceptedFileTypes="image/*, .pdf"
              maxFiles={1}
              showError={!!onboard.errors.proofOfInsurance}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="licenseNumber"
              label="License number"
              name="licenseNumber"
              value={onboard.state.licenseNumber}
              onChange={e => {
                onboard.handleInput("licenseNumber", e.target.value);
              }}
              onBlur={e => {
                onboard.handleInput("licenseNumber", e.target.value);
              }}
              error={!!onboard.errors.licenseNumber}
              helperText={onboard.errors.licenseNumber as string}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="license-state-select-input">
                License State
              </InputLabel>
              <Select
                value={onboard.state.licenseState}
                error={!!onboard.errors.licenseState}
                labelId="license-state-select-input"
                id="licenseState"
                label="License State"
                onChange={e => {
                  onboard.handleInput("licenseState", e.target.value);
                }}
                onBlur={e => {
                  onboard.handleInput("licenseState", e.target.value);
                }}
              >
                {usStatesConstant.map((usState: IUsState) => (
                  <MenuItem key={usState.mask} value={usState.value}>
                    {usState.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="bondNumber"
              label="Bond number"
              name="bondNumber"
              value={onboard.state.bondNumber}
              onChange={e => {
                onboard.handleInput("bondNumber", e.target.value);
              }}
              onBlur={e => {
                onboard.handleInput("bondNumber", e.target.value);
              }}
              error={!!onboard.errors.bondNumber}
              helperText={onboard.errors.bondNumber as string}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

function handlePhone(value: string): Record<string, unknown> {
  if (!value) return { formatted: value, unformatted: value };

  const nonDigits = /\D/g;

  const phoneNumber = value.replace(nonDigits, "").slice(0, 10);
  const area = phoneNumber.slice(0, 3);
  const prefix = phoneNumber.slice(3, 6);
  const line = phoneNumber.slice(6, 10);
  let formatting;

  if (phoneNumber.length < 4) {
    formatting = phoneNumber;
  } else if (phoneNumber.length >= 4 && phoneNumber.length < 7) {
    formatting = `(${area}) ${prefix}`;
  } else {
    formatting = `(${area}) ${prefix}-${line}`;
  }

  return { formatted: formatting, unformatted: phoneNumber };
}

export { OnboardProfile };
export default OnboardProfile;
