import React, { FC, Fragment } from "react";
import { useOnboard } from "hooks/useOnboard";
import {
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { YZTypography } from "@yardzen-inc/react-common";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    name: {
      color: "#808080",
    },
  })
);

const OnboardConfirm: FC = () => {
  const onboard = useOnboard();
  const classes = useStyles();

  const accountFields = [
    {
      name: "Business name",
      value: onboard.state.businessName,
    },
    {
      name: "First name",
      value: onboard.state.firstName,
    },
    {
      name: "Last name",
      value: onboard.state.lastName,
    },
    {
      name: "Year founded",
      value: onboard.state.yearFounded,
    },
    {
      name: "# of employees",
      value: onboard.state.numberOfEmployees,
    },
    {
      name: "Street",
      value: onboard.state.street,
    },
    {
      name: "City",
      value: onboard.state.city,
    },
    {
      name: "State",
      value: onboard.state.state,
    },
    {
      name: "Zip",
      value: onboard.state.zip,
    },
    {
      name: "Phone",
      value: onboard.state.phone,
    },
    {
      name: "Website",
      value: onboard.state.website,
    },
    {
      name: "License number",
      value: onboard.state.licenseNumber,
    },
    {
      name: "License state",
      value: onboard.state.licenseState,
    },
    {
      name: "Bond number",
      value: onboard.state.bondNumber,
    },
  ];

  return (
    <>
      <Box textAlign="center">
        {/* Add component to YZTypography when bug is fixed */}
        <YZTypography variant="h4" type="serif">
          Almost done, review and confirm your info...
        </YZTypography>
      </Box>
      <Box mt={6}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="account-info-content"
            id="account-info-header"
          >
            <YZTypography variant="h5" type="serif">
              Account Info
            </YZTypography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="center" spacing={3}>
              {accountFields.map(
                field =>
                  field.value && (
                    <Fragment key={field.name}>
                      <Grid item xs={4} sm={2}>
                        <YZTypography
                          variant="body2"
                          align="right"
                          className={classes.name}
                        >
                          {field.name}
                        </YZTypography>
                      </Grid>
                      <Grid item xs={8} sm={10}>
                        <YZTypography>{field.value}</YZTypography>
                      </Grid>
                    </Fragment>
                  )
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="locations-content"
            id="locations-header"
          >
            <YZTypography variant="h5" type="serif">
              Locations
            </YZTypography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs={4} sm={2}>
                <YZTypography
                  variant="body2"
                  align="right"
                  className={classes.name}
                >
                  Work Zones
                </YZTypography>
              </Grid>
              <Grid item xs={8} sm={10}>
                <YZTypography>
                  You have indicated {onboard.state.locations.length}{" "}
                  {onboard.state.locations.length === 1
                    ? "work zone"
                    : "work zones"}{" "}
                  on the map
                </YZTypography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="preferences-content"
            id="preferences-header"
          >
            <YZTypography variant="h5" type="serif">
              Preferences
            </YZTypography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs={4} sm={2}>
                <YZTypography
                  variant="body2"
                  align="right"
                  className={classes.name}
                >
                  Partial yard jobs
                </YZTypography>
              </Grid>
              <Grid item xs={8} sm={10}>
                <YZTypography>{onboard.state.maxJobsUnder50k}</YZTypography>
              </Grid>
              <Grid item xs={4} sm={2}>
                <YZTypography
                  variant="body2"
                  align="right"
                  className={classes.name}
                >
                  Full yard jobs
                </YZTypography>
              </Grid>
              <Grid item xs={8} sm={10}>
                <YZTypography>{onboard.state.maxJobsOver50k}</YZTypography>
              </Grid>
              <Grid item xs={4} sm={2}>
                <YZTypography
                  variant="body2"
                  align="right"
                  className={classes.name}
                >
                  Project types
                </YZTypography>
              </Grid>
              <Grid item xs={8} sm={10}>
                <YZTypography>
                  {onboard.state.specialties.map((zip, index, arr) =>
                    index !== arr.length - 1 ? `${zip}, ` : `${zip}`
                  )}
                </YZTypography>
              </Grid>
              <Grid item xs={4} sm={2}>
                <YZTypography
                  variant="body2"
                  align="right"
                  className={classes.name}
                >
                  Typical clients
                </YZTypography>
              </Grid>
              <Grid item xs={8} sm={10}>
                <YZTypography>{onboard.state.typicalClients}</YZTypography>
              </Grid>
              <Grid item xs={4} sm={2}>
                <YZTypography
                  variant="body2"
                  align="right"
                  className={classes.name}
                >
                  Jobs typically avoided
                </YZTypography>
              </Grid>
              <Grid item xs={8} sm={10}>
                <YZTypography>
                  {onboard.state.jobsTypicallyAvoided}
                </YZTypography>
              </Grid>
              <Grid item xs={4} sm={2}>
                <YZTypography
                  variant="body2"
                  align="right"
                  className={classes.name}
                >
                  Ideal job size
                </YZTypography>
              </Grid>
              <Grid item xs={8} sm={10}>
                <YZTypography>{onboard.state.idealJobSize}</YZTypography>
              </Grid>
              <Grid item xs={4} sm={2}>
                <YZTypography
                  variant="body2"
                  align="right"
                  className={classes.name}
                >
                  Will take smaller jobs
                </YZTypography>
              </Grid>
              <Grid item xs={8} sm={10}>
                <YZTypography>
                  {onboard.state.openToUnder15k ? "Yes" : "No"}
                </YZTypography>
              </Grid>
              <Grid item xs={4} sm={2}>
                <YZTypography
                  variant="body2"
                  align="right"
                  className={classes.name}
                >
                  Will take planting only jobs
                </YZTypography>
              </Grid>
              <Grid item xs={8} sm={10}>
                <YZTypography>
                  {onboard.state.openToPlantingOnly ? "Yes" : "No"}
                </YZTypography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export { OnboardConfirm };
export default OnboardConfirm;
