import React, { FC } from "react";
import { Box } from "@material-ui/core";
import DashboardProjectSummary from "components/dashboard/DashboardProjectSummary";

const tempProjects = [
  {
    id: 0,
    firstName: "Trevor",
    lastName: "L",
    ype: "Full yard",
    city: "San Rafael",
    state: "CA",
    zip: "12345",
    minimumBudget: 45000,
    maximumBudget: 60000,
    startDate: "Within 3 months",
    attributes: ["pool", "sloped", "outdoor kitchen"],
  },
  {
    id: 1,
    firstName: "Adam",
    lastName: "Mish",
    type: "Partial yard",
    city: "Foster City",
    state: "CA",
    zip: "12345",
    minimumBudget: 65000,
    maximumBudget: 90000,
    startDate: "Within 3 months",
    attributes: ["pool", "sloped"],
  },
];

const Dashboard: FC = () => (
  <Box mt={4}>
    {tempProjects.map(project => (
      <DashboardProjectSummary project={project} key={project.id} />
    ))}
  </Box>
);

export default Dashboard;
export { Dashboard };
