import React, { FC } from "react";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import { YZTypography, YZButton } from "@yardzen-inc/react-common";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

interface IDashboardProjectSummaryProps {
  project: Record<string, unknown>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: "100%",
      height: "100%",
      minHeight: "200px",
      borderRadius: "10px",
      objectFit: "cover",
    },
    attributes: {
      border: "1px solid lightgrey",
      padding: "5px",
      borderRadius: "3px",
      lineHeight: "1",
      textTransform: "lowercase",
    },
    timeDetails: {
      padding: "5px 8px",
      borderRadius: "3px",
      display: "inline-block",
    },
  })
);

const temporaryImage =
  "https://s3-alpha-sig.figma.com/img/ca9e/f51a/b3ee071d2b2b5c91789d54d803db48f2?Expires=1628467200&Signature=VQmVaXFD32zGGQdtmEtXxrZwvkmw8daGvCuj~TK3yh1f62QlAJq9X9KaqmPz00eAxBmU1J8Xcp3Megjo4sidIjaKVICU5-LwAaYaS7BzpcZJRn6RhL6O36CkUkrFHrGfKr7KPEfuJTLW4pw9s6BCjZK~mT2FDVA0f-bXEulmpIvt-ee6ZyxCO1Kkn~Jv3c9myqIcRW9NFPKcMMl0uwMZPrrGk4PsFNzeruvCg9EAlvGrDOd03iWnQQX0E7T6DIBzxw3wrgH3cBKOcraD3ZTf9pW~J4s5f3j24dncqodvoFxbfKxQxkSnTFw3Bx9zX~qPI3NFLM2d7z1~DYqAfg1kHw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA";

const DashboardProjectSummary: FC<IDashboardProjectSummaryProps> = (
  props: IDashboardProjectSummaryProps
) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { project } = props;

  return (
    <Box mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <img src={temporaryImage} alt="Temporary" className={classes.image} />
        </Grid>
        <Grid item xs={12} sm={8} md={5}>
          <Box>
            <YZTypography style={{ color: "#4c4c4c" }}>
              {project.firstName} {project.lastName}. {project.type} in{" "}
              {project.city}, {project.state} - {project.zip}
            </YZTypography>
          </Box>
          <Box mt={1}>
            <YZTypography variant="h4">
              ${(project.minimumBudget as string).toLocaleString()} -{" "}
              {(project.maximumBudget as string).toLocaleString()}
            </YZTypography>
          </Box>
          <Box display="flex" alignItems="center" mt={1}>
            <Box>
              <DateRangeIcon color="primary" />
            </Box>
            <Box ml={1}>
              <YZTypography style={{ color: "#4c4c4c" }}>
                Start date: {project.startDate}
              </YZTypography>
            </Box>
          </Box>
          <Box mt={1} display="flex" flexWrap="wrap">
            {(project.attributes as []).map(attribute => (
              <Box mr={1} mb={1} key={attribute}>
                <YZTypography variant="body2" className={classes.attributes}>
                  {attribute}
                </YZTypography>
              </Box>
            ))}
          </Box>
          <Box mt={1} display="flex">
            <Box>
              <YZButton
                color="primary"
                // onClick={handleDetails}
              >
                View details
              </YZButton>
            </Box>
            <Box ml={2}>
              <YZButton
              // onClick={handlePass}
              >
                Pass
              </YZButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Box mb={1} textAlign={isMobile ? "left" : "right"}>
            <YZTypography
              variant="body2"
              className={classes.timeDetails}
              style={{
                backgroundColor: "#dfabab",
              }}
            >
              4 hours left to reply
            </YZTypography>
          </Box>
          <Box mb={1} textAlign={isMobile ? "left" : "right"}>
            <YZTypography
              variant="body2"
              className={classes.timeDetails}
              style={{
                backgroundColor: "#f2f2f2",
              }}
            >
              <AccessTimeIcon fontSize="inherit" /> Sent 1 week ago
            </YZTypography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardProjectSummary;
export { DashboardProjectSummary };
