import { Contractor } from "@yardzen-inc/models";
import firebase from "firebase";

async function getContractorById(uid: string): Promise<Contractor | null> {
  try {
    const snapshot = await firebase
      .firestore()
      .collection("contractors")
      .where("userId", "==", uid)
      .limit(1)
      .get();

    if (snapshot.docs.length) {
      return Contractor.hydrate(snapshot.docs)[0];
    }
    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
}

export default getContractorById;
export { getContractorById };
