import { Media } from "@yardzen-inc/models";
import { firestore, storage } from "firebase/app";
import "firebase/storage";
import LogRocket from "logrocket";

const getMediaWithDownloadURL = async (
  snapshot: firestore.QuerySnapshot
): Promise<Media[]> => {
  const recordPromises: Media[] = [];

  try {
    snapshot.docs.forEach(doc => {
      const docData = doc.data();
      recordPromises.push(
        (new Promise(resolve => {
          storage()
            .ref(doc.data().path)
            .getDownloadURL()
            .then(downloadURL => {
              docData.id = doc.id;
              docData.downloadURL = downloadURL;
              resolve(new Media(docData));
            });
        }) as unknown) as Media
      );
    });

    return Promise.all(recordPromises);
  } catch (error) {
    const msg = `Error resolving download URLs: ${JSON.stringify(error)}`;
    LogRocket.captureMessage(msg);
    throw error;
  }
};

export { getMediaWithDownloadURL };
export default getMediaWithDownloadURL;
