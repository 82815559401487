/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { apps, auth } from "firebase";
import "firebase/auth";

function useUser(): {
  state: firebase.User | null | false;
  error: Error | null;
} {
  const [state, setState] = useState<firebase.User | null | false>(null);
  const [error, setError] = useState<Error | null>(null);
  useEffect(listenForAuthEvents, []);
  return { state, error };

  function listenForAuthEvents(): (() => void) | void {
    if (!apps.length) {
      return setError(new Error("APP_NOT_INITIALIZED"));
    }
    if (auth().currentUser) {
      setState(auth().currentUser);
    }

    return auth().onAuthStateChanged(
      activeUser => {
        if (!activeUser) {
          return setState(false);
        }

        return setState(activeUser);
      },
      loginError => {
        setError((loginError as unknown) as Error);
      }
    );
  }
}

export default useUser;
export { useUser };
