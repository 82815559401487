import { Circle, GeoJSON, Layer, LayerGroup, Polygon, Util } from "leaflet";
import { IGeoJSONObject, IGeoShape } from "types/leaflet/IGeoShape";
import { LeafletShape } from "types/leaflet/LeafletShape";

function mapLayerToGeoShape(layer: Layer): IGeoShape {
  const shapeType = getShapeTypeFromLayer(layer);
  return {
    id: Util.stamp(layer),
    layer: {
      type: shapeType,
      feature: buildGeoJsonFromLayer(shapeType, layer),
    },
  };
}

function getShapeTypeFromLayer(layer: Layer): LeafletShape {
  switch (layer.constructor) {
    case Circle:
      return LeafletShape.CIRCLE;
    case Polygon:
      return LeafletShape.POLYGON;
    default:
      return LeafletShape.UNSUPPORTED;
  }
}

function buildGeoJsonFromLayer(
  type: LeafletShape,
  layer: Layer
): IGeoJSONObject {
  switch (type) {
    case LeafletShape.CIRCLE:
      return buildGeoJsonFromCircle(layer as Circle);
    default:
      return (layer as LayerGroup).toGeoJSON(7) as IGeoJSONObject;
  }
}

function buildGeoJsonFromCircle(layer: Circle): IGeoJSONObject {
  return {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: GeoJSON.latLngToCoords(layer.getLatLng()),
    },
    properties: {
      point_type: "circle",
      radius: layer.getRadius(),
    },
  } as IGeoJSONObject;
}

export default mapLayerToGeoShape;
export { mapLayerToGeoShape };
