/* eslint-disable react/prop-types */
import React, { FC, useState, SyntheticEvent, MouseEvent } from "react";
import { Box, Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { YZTypography } from "@yardzen-inc/react-common";
import TerritoryMap from "components/leaflet/TerritoryMap";

const OnboardLocationWithMap: FC = () => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  return (
    <>
      <Box textAlign="center">
        {/* Add component to YZTypography when bug is fixed */}
        <YZTypography variant="h4" type="serif">
          Tell us where you work
        </YZTypography>
        <Box mt={1}>
          <YZTypography variant="body1">
            We&apos;ll send you jobs in these areas. You can update these
            preferences at anytime.
          </YZTypography>
        </Box>
      </Box>
      <Box mt={6}>
        <Box>
          <TerritoryMap />

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            action={
              <>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleSnackbarClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            }
          />
        </Box>
      </Box>
    </>
  );

  function handleSnackbarClose(
    e: SyntheticEvent | MouseEvent,
    reason?: string
  ): void {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
    setSnackbarMessage("");
  }
};

export { OnboardLocationWithMap };
export default OnboardLocationWithMap;
