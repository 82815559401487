/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@material-ui/core";
import React, { useState, useEffect, FC } from "react";
import L from "leaflet";
import { FeatureGroup } from "react-leaflet";
import { isEqual } from "lodash";
import { IGeoShape } from "types/leaflet/IGeoShape";
import { mapLayerToGeoShape } from "convertors/leaflet/mapLayerToGeoShape";

import LeafletBase from "components/leaflet/LeafletBase";
import LeafletDraw from "components/leaflet/LeafletDraw";
import useOnboard from "hooks/useOnboard";
import { createLayerFromGeoJsonString } from "convertors/leaflet/createLayerFromGeoShape";

export interface TerritoryMapProps {
  onCreate?: void;
}

const TerritoryMap: FC<TerritoryMapProps> = () => {
  const onboard = useOnboard();
  /*
    TODO: Map gets re-render when new layer is added unsure if this is an expected behavior, but it will trigger
    temporaryGetContractorFeatureCollection gets call and created multiple layers of the same shape to the map
    I need to create the following value to prevent re-rendering from happening
   */
  const [initialized, setInitialized] = useState(false);
  const [features, setFeatures] = useState<IGeoShape[]>([]);

  useEffect(() => {
    if (initialized) {
      onboard.state.locations = features.map(feature =>
        JSON.stringify(feature.layer)
      );
    }
  }, [features, initialized]);

  return (
    <Box width="100%" height="600px">
      <LeafletBase>
        <FeatureGroup
          ref={featureGroupRef =>
            onFeatureGroupReady(featureGroupRef, onboard.state.locations)
          }
        >
          <LeafletDraw
            onCreated={layer => {
              setFeatures(items => [...items, layer]);
            }}
            onEdited={layers => {
              const idList = layers.map(layer => layer.id);
              setFeatures(items => [
                ...items.filter(item => idList.indexOf(item.id) === -1),
                ...layers,
              ]);
            }}
            onDeleted={layers => {
              setFeatures(items =>
                items.filter(item => !isLayersContainsFeature(layers, item))
              );
            }}
          />
        </FeatureGroup>
      </LeafletBase>
    </Box>
  );

  function isLayersContainsFeature(
    layers: IGeoShape[],
    feature: IGeoShape
  ): boolean {
    return layers
      .map(layer => isEqual(feature, layer))
      .reduce((previousValue, currentValue) => previousValue && currentValue);
  }

  function onFeatureGroupReady(
    leafletFG: L.FeatureGroup | null,
    locations: string[]
  ): void {
    if (leafletFG && !initialized) {
      const leafletGeoJSON = locations.map(location =>
        createLayerFromGeoJsonString(location)
      );

      leafletGeoJSON.forEach(layer => {
        if (layer) {
          leafletFG.addLayer(layer);
        }
      });

      temporaryGetContractorFeatureCollection(leafletFG);
      setInitialized(true);
    }
  }

  function temporaryGetContractorFeatureCollection(
    leafletFG: L.FeatureGroup
  ): void {
    try {
      setFeatures(
        leafletFG.getLayers().map(layer => mapLayerToGeoShape(layer))
      );
    } catch (error) {
      console.error(error);
    }
  }
};

export default TerritoryMap;
