import React, { FC, useReducer, ReactNode, useState } from "react";
import {
  OnboardStateContext,
  IOnboardState,
  IOnboardAction,
} from "contexts/onboard.context";

interface IOnboardProviderProps {
  children: ReactNode;
}

const OnboardProvider: FC<IOnboardProviderProps> = ({
  children,
}: IOnboardProviderProps) => {
  const initialState = {
    companyImage: [],
    businessName: "",
    firstName: "",
    lastName: "",
    yearFounded: "",
    numberOfEmployees: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    website: "",
    proofOfInsurance: [],
    licenseNumber: "",
    licenseState: "",
    bondNumber: "",
    locations: [],
    maxJobsUnder50k: 8,
    maxJobsOver50k: 4,
    specialties: [],
    typicalClients: "",
    jobsTypicallyAvoided: "",
    idealJobSize: null,
    openToUnder15k: true,
    openToPlantingOnly: true,
  };

  const [state, dispatch] = useReducer(onboardReducer, initialState);
  const [errors, setErrors] = useState<Record<string, unknown>>({});
  const value = { state, dispatch, errors, setErrors };

  return (
    <OnboardStateContext.Provider value={value}>
      {children}
    </OnboardStateContext.Provider>
  );
};

function onboardReducer(
  state: IOnboardState,
  action: IOnboardAction
): IOnboardState {
  switch (action.type) {
    case "HANDLE INPUT": {
      return { ...state, [action.key]: action.value };
    }
    case "HANDLE ARRAY": {
      if (!Array.isArray(state[action.key])) {
        throw new Error(
          `"${action.type}" requires the value of your key to be an array.
          Value is currently a ${typeof state[action.key]}.`
        );
      }

      const oldState = state[action.key] as string[];

      if (oldState.includes(action.value as string)) {
        return {
          ...state,
          [action.key]: oldState.filter(p => p !== action.value),
        };
      }

      return {
        ...state,
        [action.key]: oldState.concat(action.value as string),
      };
    }
    case "HANDLE HYDRATE": {
      return { ...state, ...(action.value as IOnboardState) };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export { OnboardProvider };
export default OnboardProvider;
