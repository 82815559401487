function formatZipCode(value: string): string {
  const onlyNumbers = /^[0-9]+$/g;
  const nonDigits = /\D/g;

  if (value.match(onlyNumbers)) {
    return value.slice(0, 5);
  }

  return value.replace(nonDigits, "");
}

export default formatZipCode;
