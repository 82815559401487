/* eslint-disable react/prop-types */
import React, { FC } from "react";
import { MapContainer, TileLayer } from "react-leaflet";

interface ILeafletProps {
  startLatLon?: [number, number];
  startZoom?: number;
}

const LeafletBase: FC<ILeafletProps> = ({
  startLatLon = [37.855, -122.485],
  startZoom = 8,
  children,
}) => (
  <div style={{ width: "100%", height: "100%", display: "flex" }}>
    <MapContainer
      style={{
        width: "95%",
        height: "100%",
        margin: "auto",
        marginTop: "2rem",
      }}
      center={startLatLon}
      zoom={startZoom}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {children}
    </MapContainer>
  </div>
);

export default LeafletBase;
