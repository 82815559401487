import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Contractor } from "@yardzen-inc/models";
import useUser from "hooks/useUser";
import useOnboard from "hooks/useOnboard";
import { useContractorLocationsMap } from "@yardzen-inc/react-split";
import OnboardProfile from "components/onboard/OnboardProfile";
import OnboardLocation from "components/onboard/OnboardLocation";
import OnboardLocationWithMap from "components/onboard/OnboardLocationWithMap";
import OnboardPreferences from "components/onboard/OnboardPreferences";
import OnboardConfirm from "components/onboard/OnboardConfirm";
import OnboardError from "components/onboard/OnboardError";
import OnboardFooter from "components/onboard/OnboardFooter";
import OnboardStepper from "components/onboard/OnboardStepper";
import { firestore } from "firebase";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "calc(100vh - 78px)",
      position: "relative",
    },
    footer: {
      position: "absolute",
      bottom: "0",
      width: "100%",
    },
  })
);

const Onboard: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const user = useUser();
  const onboard = useOnboard();
  const contractorLocationsMap = useContractorLocationsMap();

  const { activeStep } = useParams<{ activeStep: string }>();

  const [nextStep, setNextStep] = useState<string>("");
  const [previousStep, setPreviousStep] = useState<string>("");
  const [currentStep, setCurrentStep] = useState<string>("");
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  useEffect(() => {
    const steps = ["profile", "location", "preferences", "confirm"];

    const index = steps.findIndex(step => step === activeStep);
    setCurrentStepIndex(index);
    setCurrentStep(steps[index]);
    setNextStep(steps[index + 1]);
    setPreviousStep(steps[index - 1]);
  }, [activeStep]);

  async function handleNext(): Promise<void> {
    const errors = await onboard.handleErrors(currentStep);

    const firstInvalidInput = getErrorElement(Object.entries(errors));

    if (firstInvalidInput) {
      const elementId = firstInvalidInput[0];
      const element = document.getElementById(elementId);
      element?.scrollIntoView();
      return;
    }

    if (activeStep !== "confirm") {
      history.push(`/welcome/${nextStep}`);
    } else {
      try {
        if (user.state) {
          await Contractor.create({
            ...onboard.state,
            userId: user.state.uid,
            email: user.state.email as string,
            approved: false,
            acceptingWork: false,
            internalRating: null,
            applicationSubmittedAt: firestore.FieldValue.serverTimestamp(),
          });
          // Reloads page, which redirects to "/"
          history.go(0);
        }
      } catch (err) {
        history.push("/welcome/error");
      }
    }
  }

  function handlePrevious(): void {
    history.push(`/welcome/${previousStep}`);
  }

  function buttonText(): string {
    if (activeStep === "confirm") {
      return "Confirm and create profile";
    }
    if (activeStep === "submitted") {
      return "Log into your new account";
    }
    return "Continue and save";
  }

  return (
    <>
      <Box pb={8} className={classes.root}>
        <OnboardStepper activeStep={currentStepIndex} />
        <Box my={6} mb={14}>
          {activeStep === "profile" && <OnboardProfile />}
          {activeStep === "location" &&
            (contractorLocationsMap ? (
              <OnboardLocationWithMap />
            ) : (
              <OnboardLocation />
            ))}
          {activeStep === "preferences" && <OnboardPreferences />}
          {activeStep === "confirm" && <OnboardConfirm />}
          {activeStep === "error" && <OnboardError />}
        </Box>
        <Box pb={4} textAlign="center" className={classes.footer}>
          <OnboardFooter
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            buttonText={buttonText}
          />
        </Box>
      </Box>
    </>
  );

  function getErrorElement(
    errorEntries: [string, unknown][]
  ): [string, unknown] | undefined {
    return errorEntries.find(([elementId, errorText]) => {
      if (typeof errorText === "string" && errorText.length) {
        return true;
      }
      return false;
    });
  }
};

export { Onboard };
export default Onboard;
