import React, { FC, ReactNode, useEffect } from "react";
import UserContext from "contexts/user.context";
import useUser from "hooks/useUser";

interface IUserProviderProps {
  children: ReactNode;
}

const UserProvider: FC<IUserProviderProps> = ({
  children,
}: IUserProviderProps) => {
  const { state, error } = useUser();
  useEffect(handleError, [error]);

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;

  function handleError(): void {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
};

export default UserProvider;
export { UserProvider };
