/* eslint-disable react/prop-types */
import React, { FC } from "react";
import { SpecialtyTag, IdealJobSizes } from "@yardzen-inc/models";
import { useOnboard } from "hooks/useOnboard";
import {
  Box,
  Slider,
  TextField,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { YZTypography } from "@yardzen-inc/react-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primary: {
      color: theme.palette.primary.main,
    },
    fieldset: {
      width: "100%",
    },
    formGroup: {
      justifyContent: "center",
    },
    flexItem: {
      margin: "0",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      [theme.breakpoints.up("sm")]: {
        width: "50%",
      },
      [theme.breakpoints.up("md")]: {
        width: "25%",
      },
    },
  })
);

const OnboardPreferences: FC = () => {
  const onboard = useOnboard();
  const classes = useStyles();

  const projectTypes: SpecialtyTag[] = [
    "Planting",
    "Hardscaping",
    "Fencing",
    "Retaining walls",
    "Decks",
    "ADU construction",
    "Spa installation",
    "Electrical/lighting",
    "Irrigation",
    "Pergolas",
    "Custom pools",
    "Plunge pools",
  ];

  const jobSizes: IdealJobSizes[] = ["15k-30k", "30k-50k", "50k-100k", "100k+"];

  return (
    <>
      <Box textAlign="center">
        {/* Add component to YZTypography when bug is fixed */}
        <YZTypography variant="h4" type="serif">
          Tell us how many jobs you want
        </YZTypography>
        <Box mt={1}>
          <YZTypography variant="body1">
            We&apos;ll match you to jobs where we think you&apos;re a great fit,
            but we won&apos;t exceed your max. Don&apos;t worry, you can update
            us as your availability changes
          </YZTypography>
        </Box>
        <Box mt={6}>
          <YZTypography variant="button">Partial Yard</YZTypography>
          <YZTypography variant="h5" type="serif" id="partial-yard-jobs">
            I can take{" "}
            <Box component="span" className={classes.primary}>
              {onboard.state.maxJobsUnder50k}
            </Box>{" "}
            small projects (under $50k) at the same time
          </YZTypography>
          <Box mt={4}>
            <Slider
              aria-labelledby="partial-yard-jobs"
              valueLabelDisplay="auto"
              value={onboard.state.maxJobsUnder50k}
              step={1}
              marks
              min={0}
              max={16}
              onChange={(e, value) => {
                onboard.handleInput("maxJobsUnder50k", value);
              }}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <YZTypography variant="button">Full Yard</YZTypography>
          <YZTypography variant="h5" type="serif" id="full-yard-jobs">
            I can take{" "}
            <Box component="span" className={classes.primary}>
              {onboard.state.maxJobsOver50k}
            </Box>{" "}
            large projects (over $50k) at the same time
          </YZTypography>
          <Box mt={4}>
            <Slider
              aria-labelledby="full-yard-jobs"
              valueLabelDisplay="auto"
              value={onboard.state.maxJobsOver50k}
              step={1}
              marks
              min={0}
              max={8}
              onChange={(e, value) => {
                onboard.handleInput("maxJobsOver50k", value);
              }}
            />
          </Box>
        </Box>
        <Box mt={8}>
          <YZTypography variant="h4" type="serif">
            Tell us about your preferences
          </YZTypography>
          <Box mt={6}>
            <YZTypography variant="h5" type="serif" id="specialties">
              Which kind of projects are you most drawn to?
            </YZTypography>
            <Box my={4}>
              <FormControl component="fieldset">
                <FormGroup
                  row
                  aria-label="Kind of projects"
                  className={classes.formGroup}
                >
                  {projectTypes.map(projectType => (
                    <FormControlLabel
                      value={projectType}
                      label={projectType}
                      key={projectType}
                      className={classes.flexItem}
                      control={
                        <Checkbox
                          color="primary"
                          value={projectType}
                          onChange={e => {
                            onboard.handleArray("specialties", e.target.value);
                          }}
                          checked={onboard.state.specialties.includes(
                            projectType
                          )}
                        />
                      }
                    />
                  ))}
                </FormGroup>
                {!!onboard.errors.specialties && (
                  <Box mt={2}>
                    <YZTypography color="error">
                      {onboard.errors.specialties as string}
                    </YZTypography>
                  </Box>
                )}
              </FormControl>
            </Box>
          </Box>
          <Box mt={6}>
            <YZTypography variant="h5" type="serif" id="typicalClients">
              How would you describe your typical clients?
            </YZTypography>
            <Box mt={2}>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={2}
                rowsMax={4}
                name="typicalClients"
                value={onboard.state.typicalClients}
                onChange={e => {
                  onboard.handleInput("typicalClients", e.target.value);
                }}
                onBlur={e => {
                  onboard.handleInput("typicalClients", e.target.value);
                }}
                error={!!onboard.errors.typicalClients}
                helperText={onboard.errors.typicalClients as string}
              />
            </Box>
          </Box>
          <Box mt={6}>
            <YZTypography variant="h5" type="serif" id="jobsTypicallyAvoided">
              What jobs do you typically avoid?
            </YZTypography>
            <Box mt={2}>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={2}
                rowsMax={4}
                name="jobsTypicallyAvoided"
                value={onboard.state.jobsTypicallyAvoided}
                onChange={e => {
                  onboard.handleInput("jobsTypicallyAvoided", e.target.value);
                }}
                onBlur={e => {
                  onboard.handleInput("jobsTypicallyAvoided", e.target.value);
                }}
                error={!!onboard.errors.jobsTypicallyAvoided}
                helperText={onboard.errors.jobsTypicallyAvoided as string}
              />
            </Box>
          </Box>
          <Box mt={6}>
            <YZTypography variant="h5" type="serif">
              What&apos;s your ideal job size?
            </YZTypography>
            <Box my={4}>
              <FormControl component="fieldset" className={classes.fieldset}>
                <RadioGroup
                  row
                  aria-label="Ideal job size"
                  id="idealJobSize"
                  name="idealJobSize"
                  value={onboard.state.idealJobSize}
                  onChange={e => {
                    onboard.handleInput("idealJobSize", e.target.value);
                  }}
                  className={classes.formGroup}
                >
                  {jobSizes.map(jobSize => (
                    <FormControlLabel
                      value={jobSize}
                      label={jobSize}
                      key={jobSize}
                      className={classes.flexItem}
                      control={<Radio color="primary" />}
                    />
                  ))}
                </RadioGroup>
                {!!onboard.errors.idealJobSize && (
                  <Box mt={2}>
                    <YZTypography color="error">
                      {onboard.errors.idealJobSize as string}
                    </YZTypography>
                  </Box>
                )}
              </FormControl>
            </Box>
          </Box>
          <Box mt={6}>
            <YZTypography variant="h5" type="serif">
              Are you sometimes able to take smaller jobs (under $15k)?
            </YZTypography>
            <Box mt={4}>
              <FormControl component="fieldset" className={classes.fieldset}>
                <RadioGroup
                  row
                  aria-label="Open to jobs under 15k"
                  id="openToUnder15k"
                  name="openToUnder15k"
                  value={onboard.state.openToUnder15k ? "Yes" : "No"}
                  onChange={e => {
                    onboard.handleInput(
                      "openToUnder15k",
                      // This converts the value from string to boolean
                      e.target.value === "Yes"
                    );
                  }}
                  className={classes.formGroup}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                    className={classes.flexItem}
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio color="primary" />}
                    label="No"
                    className={classes.flexItem}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Box mt={6}>
            <YZTypography variant="h5" type="serif">
              Are you open to taking planting only jobs?
            </YZTypography>
            <Box mt={4}>
              <FormControl component="fieldset" className={classes.fieldset}>
                <RadioGroup
                  row
                  aria-label="Open to planting only jobs"
                  id="openToPlantingOnly"
                  name="openToPlantingOnly"
                  value={onboard.state.openToPlantingOnly ? "Yes" : "No"}
                  onChange={e => {
                    onboard.handleInput(
                      "openToPlantingOnly",
                      // This converts the value from string to boolean
                      e.target.value === "Yes"
                    );
                  }}
                  className={classes.formGroup}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                    className={classes.flexItem}
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio color="primary" />}
                    label="No"
                    className={classes.flexItem}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { OnboardPreferences };
export default OnboardPreferences;
